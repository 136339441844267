import Api from '../../utils/api'
import { setData, removeData } from '../../utils/storage'

export default {
    getLoginState: async updateState => {
        try {
            updateState('home', { loading: true })
            const data = await Api.getSession()

            removeData()
            if (data.session) {
                setData()
                updateState('home', {
                    superUser: data.super,
                    sessionData: data.profile,
                    loginStatus: true,
                })
            }
        } catch (err) {
            updateState('home', { loading: false, error: err.message })
        } finally {
            updateState('home', { loading: false })
        }
    },
}
