import FunctionImport from '../Components/FunctionImport'

const Routers = [
    {
        path: '/',
        component: FunctionImport({ component: import('./Home') }),
        exact: true,
        title: 'InCommand',
    },
    {
        path: '/dashboard',
        component: FunctionImport({ component: import('./Dashboard') }),
        exact: true,
        title: 'InCommand | Dashboard',
    },
    {
        path: '/dashboard/:tab',
        component: FunctionImport({ component: import('./Dashboard') }),
        exact: true,
        title: 'InCommand | Dashboard',
    },
    {
        path: '/mobile-dashboard/:routeUrl/:userToken',
        component: FunctionImport({ component: import('./MobileDashboard') }),
        exact: true,
        title: 'InCommand | Dashboard',
    },
    {
        path: '/external-login',
        component: FunctionImport({ component: import('./ExternalLogin') }),
        exact: true,
        title: 'InCommand',
    },

    // {
    //     path: '/test',
    //     component: FunctionImport({ component: import('./Test') }),
    //     exact: true,
    //     title: 'InCommand',
    // },
]

export default Routers
