export function setData() {
    window.localStorage.setItem('session', 1)
}

export function getData() {
    return window.localStorage.getItem('session')
}

export function removeData() {
    window.localStorage.setItem('session', 0)
}
