import React from 'react';
import { render } from 'react-dom';
import { createStore } from 'actox';
import history from './utils/history'
import { getData } from './utils/storage'
import actionListener from './store/actions';
import states from './store/states';
import Pages from './pages';

import './styles/index.scss';

createStore(states, actionListener, process.env.NODE_ENV === 'developmen');
const { pathname } = window.location
const isLoggedIn = getData()
if (!isLoggedIn && (!pathname.includes('mobile-dashboard') && !pathname.includes('external-login'))) {
    if (pathname !== '/') {
        history.push('/')
    }
}

render(<Pages />, document.getElementById('root'));
