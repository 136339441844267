import mixpanel from 'mixpanel-browser';


mixpanel.init('c32f98e172050e4226bb79343f30287e');

const actions = {
  identify: () => {
     mixpanel.identify();
  },
  alias: (id) => {
     mixpanel.alias(id);
  },
  track: (name, props) => {
     mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      mixpanel.people.set(props);
    },
  },
  register: (props) => {
  mixpanel.register(props)
  },
};
const Mixpanel = actions
export default Mixpanel;
