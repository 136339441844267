/* eslint-disable max-len */
import Api from '../../utils/api'

export default {
    getNotifications: async (updateState) => {
        try {
            updateState('notification', { loading: true })
            const data = await Api.getNotifications();
            updateState('notification', { notifications: data, count: data.length })
        } catch (err) {
            console.log(err)
            updateState('notification', { loading: false, error: err.message })
        } finally {
            updateState('notification', { loading: false })
        }
    },
    getNotification: async (updateState, id) => {
        try {
            updateState('dashboard', { loading: true })
            const data = await Api.getNotification(id);
            const reportData = await Api.getReport({ group_id: data.content.powerbi.group_id, report_id: data.content.powerbi.report_id, type: data.content.powerbi.type })
            if (reportData.success) {
                const commentdata = await Api.getComment(data.content.tile_id, data.content.comment.id)
                updateState('comment', { threads: commentdata })
                updateState('dashboard', {
                    accessToken: reportData.access_token,
                    embedUrl: reportData.embed_url,
                    loading: false,
                    id: reportData.id,
                    tab: data.content.powerbi.rank - 1,
                    activeRightCard: {
                        iconName: 'chat', heading: 'Comments', type: 'comment',
                    },
                    activeTile: data.content.tile_id,
                    activeReport: reportData.page_name,
                    bearerToken: reportData.bearer_token,
                    routeUrl: reportData.route_url,
                    refreshData: reportData.refresh_data,
                })
            }
        } catch (err) {
            console.log(err)
            updateState('dashboard', { loading: false, error: err.message })
        } finally {
            updateState('dashboard', { loading: false })
        }
    },
    updateNotificationCount: async (updateState) => {
        try {
            const data = await Api.getNotificationCount();
            if (data.count) {
                updateState('notification', {
                    unreadCount: data.count,
                })
            }
        } catch (err) {
            console.log(err)
            updateState('notification', { unreadCount: 0 })
        } finally {
            updateState('notification', { loading: false })
        }
    },
}
