/* eslint-disable max-len */
/* eslint-disable no-throw-literal */
import axios from 'axios'
import Api from '../../utils/api'

export default {
    getReport: async (updateState, groupId, reportId, type, pageName) => {
        try {
            updateState('dashboard', { loading: true })
            const data = await Api.getReport({
                 group_id: groupId, report_id: reportId, type, page_name: pageName,
            })

            if (data.success) {
                updateState('dashboard', {
                    accessToken: data.access_token,
                    embedUrl: data.embed_url,
                    loading: false,
                    id: data.id,
                    reportName: data.report_name,
                    bearerToken: data.bearer_token,
                    routeUrl: data.route_url,
                    refreshData: data.refresh_data,
                    activeReport: data.page_name,
                })
            } else {
                updateState('dashboard', { error: data.message })
            }
        } catch (err) {
            console.log(err)
            updateState('dashboard', { loading: false })
        } finally {
            updateState('dashboard', { loading: false })
        }
    },
    getNetworkData: async (updateState, tabName) => {
        const data = await axios.get(`/api/get-network-model/${tabName}/`)
        updateState('dashboard', { loading: false, networkData: data.data.data })
    },
    getInitalReport: async (updateState, tab, mode = 'idx') => {
        try {
            updateState('dashboard', { loading: true })
            const report = await Api.getReports();
            if (mode === 'pkk') {
                report.every((element, idx) => {
                    if (element.pkk === tab) {
                        // eslint-disable-next-line no-param-reassign
                        tab = idx

                        return false
                    }
                    return true
                })
            }

            if (typeof (tab) === "string") {
                updateState('dashboard', { error: "No Report Found Or Don't have permission to access it" })
                throw "No Report Found"
            }
            if (tab === 0) {
                report.every((element, idx) => {
                    if (element.has_child === false) {
                        // eslint-disable-next-line no-param-reassign
                        tab = idx
                        return false
                    }
                    return true
                });
            }
            updateState('dashboard', { tab })
            if (report[tab].platform === 'route') {
                updateState('dashboard', { normalReport: false })
                const data = await axios.get(`/api/get-network-model/${report[tab].tab_name}/`)
                updateState('dashboard', { loading: false, networkData: data.data.data })
                return
            }
            const data = await Api.getReport({
                                group_id: report[tab].group_id,
                                report_id: report[tab].report_id,
                                type: report[tab].type,
                                page_name: report[tab].page_name,
                            })
            if (data.success) {
                updateState('dashboard', {
                    accessToken: data.access_token,
                    embedUrl: data.embed_url,
                    loading: false,
                    id: data.id,
                    reportName: data.report_name,
                    activeReport: data.page_name,
                    bearerToken: data.bearer_token,
                    routeUrl: data.route_url,
                    refreshData: data.refresh_data,
                })
            } else {
                updateState('dashboard', { error: data.message })
            }
        } catch (err) {
            console.log(err)
            updateState('dashboard', { loading: false })
        } finally {
            updateState('dashboard', { loading: false })
        }
    },
    getReportList: async (updateState) => {
        try {
                updateState('dashboard', { loading: true })
                const data = await Api.getReports()
                // eslint-disable-next-line no-prototype-builtins
                if (data.hasOwnProperty('success') && data.success === false) {
                    updateState('dashboard', { error: data.message })
                } else {
                    updateState('dashboard', { collection: data })
                }
            } catch (err) {
            console.log(err)
            updateState('dashboard', { loading: false, error: err.message })
        } finally {
            updateState('dashboard', {})
        }
    },
}
