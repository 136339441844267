import home from './home'
import dashboard from './dashboard'
import users from './users'
import comment from './comment'
import notification from './notification'

export default {
    ...home,
    ...dashboard,
    ...users,
    ...comment,
    ...notification,
}
