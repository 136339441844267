import React from 'react';

function FunctionImport(props) {
    return class extends React.Component {
        state = {
            Component: null,
        }

        componentDidMount() {
            const { path, component } = props;
            if (path) {
                import(`${path}`)
                    .then((comp) => {
                        this.setState({ Component: comp.default });
                    })
                    .catch((e) => {
                        this.setState({ Component: null });
                        throw e;
                    });
            } else if (component) {
                component
                    .then((module) => {
                        this.setState({ Component: module.default });
                    })
                    .catch((e) => {
                        this.setState({ Component: null });
                        console.error('Component should be default exported', e);
                    });
            }
        }

        render() {
            const { Component } = this.state;
            const { path, ...rest } = this.props;
            if (!Component) {
                return <div>Loading...</div>;
            }
            return <Component {...rest} />;
        }
    };
}
export default FunctionImport;
