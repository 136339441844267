import React from 'react';

class ComponentImport extends React.Component {
    state = {
        Component: null,
    }

    componentDidMount() {
        const { path, component } = this.props;
        if (path) {
            import(`${path}`)
                .then((cp) => {
                    this.setState({ Component: cp.default });
                })
                .catch((e) => {
                    this.setState({ Component: null });
                    throw e;
                });
        } else if (component) {
            component
                .then((module) => {
                    this.setState({ Component: module.default });
                })
                .catch((e) => {
                    this.setState({ Component: null });
                    console.error('Component should be default exported', e);
                });
        }
    }

    render() {
        const { Component } = this.state;
        const { path, ...rest } = this.props;
        if (!Component) {
            return <div>Loading...</div>;
        }
        return <Component {...rest} />;
    }
}
export default ComponentImport;
