const BASE = '/';

export const getUrl = url => `${BASE}${url}`;

export const STATIC = {
  logo: getUrl('static/image/site-logo/innovaccer-logo-black.svg'),
  styles: getUrl('static/css/styles.css'),
  bootstrap: getUrl('static/css/bootstrap.min.css'),
};

export const createConfig = (url, method) => ({
  method,
  url: getUrl(url),
});

export const API = {
  getUsers: createConfig('api/users/', 'get'),
  getSession: createConfig('api/get-session/', 'get'),
  getReport: createConfig('api/get-report/', 'post'),
  getReports: createConfig('api/reports/', 'get'),
  createComment: createConfig('api/comments/', 'post'),
  getAllComments: createConfig('api/comments/all/', 'get'),
  getNotifications: createConfig('api/notifications/', 'get'),
  getNotificationCount: createConfig('api/notifications/count/', 'get'),
  postTileVisualLink: createConfig('api/configurer/', 'post'),
  getMagicLink: createConfig('api/magic-link/', 'post'),
};
