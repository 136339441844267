import axios from 'axios';
import { API, createConfig } from '../store/constants/Endpoints';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

export default {
  getUsers: async () => {
    const r = await axios({ ...API.getUsers });
    return r.data;
  },
  getSession: async () => {
    const r = await axios({ ...API.getSession });
    return r.data;
  },
  getReport: async (data) => {
    const r = await axios({ ...API.getReport, data });
    return r.data;
  },
  getReports: async () => {
    const r = await axios({ ...API.getReports });
    return r.data;
  },
  createComment: async (data) => {
    const r = await axios({ ...API.createComment, data });
    return r.data
  },
  getComment: async (tileId, id = null) => {
    const r = await axios({ ...createConfig(id ? `api/comments/${tileId}/?id=${id}` : `api/comments/${tileId}/`, 'get') });
    return r.data
  },
  getAllComments: async () => {
    const r = await axios({ ...API.getAllComments });
    return r.data
  },
  getNotifications: async () => {
    const r = await axios({ ...API.getNotifications });
    return r.data
  },
  getNotification: async (id) => {
    const r = await axios({ ...createConfig(`api/notifications/${id}/`, 'get') });
    return r.data
  },
  getNotificationCount: async () => {
    const r = await axios({ ...API.getNotificationCount });
    return r.data
  },
  postTileVisualLink: async (data) => {
    const r = await axios({ ...API.postTileVisualLink, data });
    return r.data
  },
  getTileVisualLink: async (id) => {
    const r = await axios({ ...createConfig(`api/configurer/${id}/`, 'get') });
    return r.data
  },
  getMagicLink: async (data) => {
    const r = await axios({ ...API.getMagicLink, data });
    return r.data
  },
};
