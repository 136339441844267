export default {
    loading: true,
    error: null,
    tab: 0,
    accessToken: null,
    embedUrl: null,
    id: null,
    collection: [],
    activeRightCard: {},
    activeTile: null,
    activeReport: null,
    bearerToken: null,
    routeUrl: null,
    refreshData: {},
    networkData: false,
    normalReport: true,
}
