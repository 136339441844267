import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { positions, Provider } from "react-alert"
import AlertTemplate from "react-alert-template-basic";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import ComponentImport from '../Components/ComponentImport'
import history from '../utils/history'
import RouterRoot from './routerRoot'
import Mixpanel from '../mixpanel/mixpanel';

const options = {
    timeout: 2500,
    positions: positions.BOTTOM_RIGHT,
    containerStyle: {
        zIndex: 99999,
    },
}

const theme = createMuiTheme({
    palette: {
        primary: { main: '#E31C79' },
        secondary: { main: '#000000' },
    },
    typography: {
        useNextVariants: true,
        fontFamily: '"FranklinGothic", Arial, Helvetica, sans-serif',
    },
})

const Base = props => {
    return (
        <Provider template={AlertTemplate} {...options}>
            <MuiThemeProvider theme={theme}>
                <Router history={history}>
                    <Switch>
                        {RouterRoot.map(r => (
                            <Route
                                exact={r.exact}
                                key={r.title}
                                path={r.path}
                                render={route => (
                                    <div onLoad={() => { Mixpanel.identify(); Mixpanel.track('Page Details', { 'Page Name': r.title, 'Page Url': r.path }) }}>
                                        <Helmet>
                                            <title>{r.title}</title>
                                            <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
                                            <link rel="apple-touch-icon-precomposed" sizes="57x57" href="static/images/favicons/apple-touch-icon-57x57.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="60x60" href="static/images/favicons/apple-touch-icon-60x60.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="72x72" href="static/images/favicons/apple-touch-icon-72x72.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="76x76" href="static/images/favicons/apple-touch-icon-76x76.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="114x114" href="static/images/favicons/apple-touch-icon-114x114.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="120x120" href="static/images/favicons/apple-touch-icon-120x120.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="144x144" href="static/images/favicons/apple-touch-icon-144x144.png" />
                                            <link rel="apple-touch-icon-precomposed" sizes="152x152" href="static/images/favicons/apple-touch-icon-152x152.png" />
                                            <link rel="icon" type="image/png" sizes="196x196" href="static/images/favicons/android-icon-196x196.png" />
                                            <link rel="icon" type="image/png" sizes="96x96" href="static/images/favicons/favicon-96x96.png" />
                                            <link rel="icon" type="image/png" sizes="32x32" href="static/images/favicons/favicon-32x32.png" />
                                            <link rel="icon" type="image/png" sizes="16x16" href="static/images/favicons/favicon-16x16.png" />
                                            <link rel="icon" type="image/png" sizes="126x128" href="static/images/favicons/favicon-128.png" />
                                        </Helmet>
                                        <r.component {...props} {...route} />
                                    </div>
                                )}
                            />
                        ))}
                        <Route render={() => <ComponentImport component={import('./NoPageFound')} />} />
                    </Switch>
                </Router>
            </MuiThemeProvider>
        </Provider>
    )
}

export default Base
