/* eslint-disable max-len */
import _ from 'lodash'
import Api from '../../utils/api'

export default {
    createComment: async (updateState, comment, users, tileId, tabName, threads, threadIdx) => {
        try {
            updateState('comment', { loading: true })
            const parent = threads.length > 0 && threadIdx !== -1 ? threads[threadIdx].id : null
            const data = await Api.createComment({
                                comment, users, tile_id: tileId, tab_name: tabName, parent,
                                })
            if (parent) {
                const newThread = threads
                if (_.has(newThread[threadIdx], 'children')) { newThread[threadIdx].children.push(data) } else if (_.has(newThread[threadIdx], 'last_child')) { newThread[threadIdx].last_child.push(data) }
                updateState('comment', { threads: newThread })
            } else {
                updateState('comment', { threads: [...threads, data] })
            }
        } catch (err) {
            console.log(err)
            updateState('comment', { loading: false, error: err.message })
        } finally {
            updateState('comment', { loading: false })
        }
    },
    getComment: async (updateState, tileId, commentId = null) => {
        try {
            updateState('comment', { loading: true, threads: [] })
            let data = []
            if (commentId) { data = await Api.getComment(tileId, commentId) } else { data = await Api.getComment(tileId) }
            updateState('comment', { threads: data })
        } catch (err) {
            console.log(err)
            updateState('comment', { loading: false, error: err.message })
        } finally {
            updateState('comment', { loading: false })
        }
    },
    getAllComments: async (updateState) => {
        try {
            updateState('comment', { loading: true, threads: [] })
            const data = await Api.getAllComments()
            updateState('comment', { threads: data })
        } catch (err) {
            console.log(err)
            updateState('comment', { loading: false, error: err.message })
        } finally {
            updateState('comment', { loading: false })
        }
    },
}
