/* eslint-disable max-len */
import Api from '../../utils/api'

export default {
    getUsers: async (updateState) => {
        try {
            updateState('users', { loading: true })
            const data = await Api.getUsers()
            updateState('users', {
                loading: false,
                users: data,
            })
        } catch (err) {
            console.log(err)
            updateState('users', { loading: false, error: err.message })
        } finally {
            updateState('users', { loading: false })
        }
    },

}
